import React from 'react';
import classNames from 'classnames';
import { withRouter } from 'react-router';
import qs from 'qs';
import './Paginate.css';

const Paginate = (props) => {

    const onPageChanged = props.onPageChanged;
    
    const curPage = props.curPage;
    const pageCount = props.pageCount;
    const itemCount = props.itemCount;
    const itemsPerPage = props.itemsPerPage;
    
    const renderPageNumberButtons = () => {
        let buttonRow = [];
        for (let i = 0; i < pageCount; i++)
        {
            buttonRow.push( (<li><a className={ 'paginate__page ' + (curPage === i+1 ? "paginate__page--is-selected" : "") } onClick={() => onPageChanged(i+1)} >{i+1}</a></li>))
        }
        return buttonRow
    }

    return (
        <div className="paginate">
            <ul>
                {curPage > 1 &&
                    <li>
                        <a className="paginate__nav paginate__nav--prev" onClick={() => onPageChanged(curPage-1)}></a>
                    </li>
                }

                { renderPageNumberButtons() }
            
                {curPage !== pageCount &&
                    <li>
                        <a className="paginate__nav paginate__nav--next" onClick={() => onPageChanged(curPage+1)}></a>
                    </li>
                }
            </ul>
            <div className="paginate__text">{(curPage-1)*itemsPerPage +1} - {curPage === pageCount ? itemCount : curPage*itemsPerPage} av totalt {itemCount}</div>
        </div>
    );
};

export default withRouter(Paginate);