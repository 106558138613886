import React from 'react';
import './WaterQuality.css';
import warningImg from '../../assets/Warning.svg';
import checkmarkImg from '../../assets/Checkmark.svg';


const WaterQuality = (props) => {
    return (
            <div className="waterquality">
                <img className="waterquality_img" src={props.isOk ? checkmarkImg : warningImg} alt="" />
                {props.isOk ? props.text : props.falseText}
            </div>
    );
};

export default WaterQuality;
