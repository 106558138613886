import React, { Component, useState, useCallback, useEffect } from 'react';
import axios from '../api/axios';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link
} from "react-router-dom";

import BG from "../assets/bg.jpg";
import { useDropzone } from 'react-dropzone'
import qs from "qs";
import OptionEntry from './OptionEntry';

const DashboardContainer = (props) => {
	// Sensor variables
	const [_id, setID] = useState("");

	// User determines which sensors we can get from the server, we can only get the ones we created with our user
	const [clientID, setClientID] = useState("");
	const [name, setName] = useState("");
	const [sensorID, setSensorID] = useState(0);
	const [longitude, setLongitude] = useState("");
	const [latitude, setLatitude] = useState("");
	const [waterquality, setWaterQuality] = useState(-1);
	const [algiers, setAlgiers] = useState(-1);
	const [image, setImage] = useState("");
	const [link, setLink] = useState("");
	const [rentKayakLink, setRentKayakLink] = useState("");
	

	const [parking, setParking] = useState(false);
	const [toilet, setToilet] = useState(false);
	const [handicap, setHandicap] = useState(false);
	const [isPrivate, setPrivate] = useState(false);
	
	
	

	// The list of spots
	var [spots, setSpots] = useState([]);
	
	// Spot editor help values
	var selectedIndex = 0
	const [updated, setUpdated] = useState(false);
	var [added, setAdded] = useState(false);
	var [removed, setRemoved] = useState(false);
	

	// Update the visible data with the selected spot list index
	const SetSelectedSpotIndex = (index) => {
		document.getElementById("spotList").selectedIndex = index;
		OnSelectedIndexChanged(index);
	}

	const OnSelectedIndexChanged = (index) => {
		// spot index starts at 1 in the list
		selectedIndex = index;
		let sI = +selectedIndex - 1;

		if (sI >= 0) {
		setID(spots[sI]._id);
		setClientID(spots[sI].clientID)
		setName(spots[sI].name);
		setSensorID(spots[sI].sensorID);
		setLongitude(spots[sI].longitude);
		setLatitude(spots[sI].latitude);
		setWaterQuality(spots[sI].waterquality);
		setAlgiers(spots[sI].algiers)
		setLink(spots[sI].link);
		setRentKayakLink(spots[sI].rentKayakLink);
		setImage(spots[sI].image);
		setParking(spots[sI].parking)
		setToilet(spots[sI].toilet)
		setHandicap(spots[sI].handicap)
		setPrivate(spots[sI].isPrivate)

		// just a fast solution to get checkboxes to work, TODO clean up
		document.getElementById("opt1").checked = spots[sI].parking
		document.getElementById("opt2").checked = spots[sI].toilet
		document.getElementById("opt3").checked = spots[sI].handicap
		}
		else {
			setID(0);
			setClientID("")
			setName("");
			setSensorID("");
			setLongitude("");
			setLatitude("");
			setWaterQuality(-1);
			setAlgiers(-1)
			setLink("");
			setRentKayakLink("");
			setImage("");
			setParking(false)
			setToilet(false)
			setHandicap(false)
			setPrivate(false)

			document.getElementById("opt1").checked = false
			document.getElementById("opt2").checked = false
			document.getElementById("opt3").checked = false
		}
	}


	const GetSpotsFromServer = () => {
		//Authentication

		const token = window.sessionStorage.getItem('loginToken')
		if (!token || token === "") {
			window.location = "/login?error=invalidtoken"
		}

		axios.get("/api/auth/verify", { params: { token: token } }).then(function (response) {
			if (!response.data.useruuid) window.location = "/login?error=invalidtoken"

			// Get spot list
			axios.get('/api/spots/list', {
				params: {
					//useruuid: response.data.useruuid, // For now the single Admin user can get all spots
					id: "",
					allspots: true,
				}
			})
				.then(function (response) {
					setSpots(response.data.spots)
					spots = response.data.spots; // Because the above code is async or something, therefore when we select spot index it won't be updated if we don't force it
					setUpdated(false)

					if (added){
						added = false;
						setAdded(false);
						SetSelectedSpotIndex(response.data.spots.length) // Set to last badplats
					}
					else if (removed){
						removed = false;
						setRemoved(false);
						SetSelectedSpotIndex(0) // set to "välj en badplats"
					}
					else{
						SetSelectedSpotIndex(selectedIndex)
					}
				})
				.catch(function (error) {
					console.log(error);
				})


		}).catch(function (error) {
			window.location = "/login?error=invalidtoken"
		});
	}

	// Usefull if we need to update the list when a variable changes
	useEffect(() => GetSpotsFromServer(), []);


	const renderForm = () => {

		if (!spots || spots.length === 0) {
			return (
				<input onClick={() => setSpots([...spots, { name: "Ny badplats" }])} type="button" value="Lägg till ny" style={{ borderRadius: 10, backgroundColor: "steelblue", color: "#fff", padding: 5, border: 0, marginLeft: 10, fontSize: 12 }} />
			)
		}
		return (
			<>
				<div style={{ marginBottom: 15 }}>
					<label style={{ fontWeight: "bold" }}>Badplatser</label>
					<br />
					<select id="spotList" onChange={(e) => OnSelectedIndexChanged(e.target.value)} style={{ width: "50%" }}>
						<option value={0}>Välj en badplats</option>
						{spots.map((spot, index) => {
							return <option value={index + 1}>{spot.name}</option>
						})}
					</select>
					<input onClick={() => addSpot()} type="button" value={added ? "Lägger till ny badplats..." : "Lägg till som ny"} style={{ borderRadius: 10, backgroundColor: "steelblue", color: "#fff", padding: 5, border: 0, marginLeft: 10, fontSize: 12 }} />
				</div>

				<OptionEntry header="Användarens ID">
					<input value={clientID} type="text" placeholder="clientID" onInput={(e) => setClientID(e.target.value)} style={{ width: "70%" }} />
				</OptionEntry>

				<OptionEntry header="Badplatsens namn">
					<input value={name} type="text" onInput={(e) => setName(e.target.value)} style={{ width: "70%" }} />
				</OptionEntry>

				<OptionEntry header="Givarens ID">
					<input value={sensorID} type="text" onInput={(e) => setSensorID(e.target.value)} style={{ width: "70%" }} />
				</OptionEntry>

				<OptionEntry header="Longitud">
					<input value={longitude} type="text" onInput={(e) => setLongitude(e.target.value)} style={{ width: "70%" }} />
				</OptionEntry>

				<OptionEntry header="Latitud">
					<input value={latitude} type="text" onInput={(e) => setLatitude(e.target.value)} style={{ width: "70%" }} />
				</OptionEntry>

				<OptionEntry header="Vattenkvalitet">
					<select value={waterquality} onChange={(e) => setWaterQuality(e.target.value)} style={{ width: "70%" }}>
						<option value={-1}>Ej angivet</option>
						<option value={0}>Otjänligt</option>
						<option value={1}>Tjänligt</option>
					</select>
				</OptionEntry>

				<OptionEntry header="Algförekomst">
					<select value={algiers} onChange={(e) => setAlgiers(e.target.value)} style={{ width: "70%" }}>
						<option value={-1}>Ej angivet</option>
						<option value={0}>Nej</option>
						<option value={1}>Ja</option>
					</select>
				</OptionEntry>

				<OptionEntry header="Bild länk">
					<input value={image} type="text" placeholder="https://" onInput={(e) => setImage(e.target.value)} style={{ width: "70%" }} />
				</OptionEntry>

				<OptionEntry header="Läs mer länk">
					<input value={link} type="text" placeholder="https://" onInput={(e) => setLink(e.target.value)} style={{ width: "70%" }} />
				</OptionEntry>

				<OptionEntry header="Hyr kayak länk">
					<input value={rentKayakLink} type="text" placeholder="https://" onInput={(e) => setRentKayakLink(e.target.value)} style={{ width: "70%" }} />
				</OptionEntry>

				<OptionEntry header="Om badplatsen">
					<input type="checkbox" id="opt1" value={parking} style={{ height: "20px", marginRight: "-10px" }} />
					<label for="opt1"> Parkering </label><br/>
					<input type="checkbox" id="opt2" value={toilet} style={{ height: "20px", marginRight: "-10px" }} />
					<label for="opt2"> Toalett </label><br/>
					<input type="checkbox" id="opt3" value={handicap} style={{ height: "20px", marginRight: "-10px" }} />
					<label for="opt3"> Handikappanpassad </label><br/>
				</OptionEntry>

				<OptionEntry header="Privat">
					<select value={isPrivate} onChange={(e) => setPrivate(e.target.value)} style={{ width: "70%" }}>
						<option value={false}>Nej</option>
						<option value={true}>Ja</option>
					</select>
				</OptionEntry>
				

				<div style={{ marginTop: 10, }}>
					<input onClick={() => updateSpot()} type="button" value={updated ? "Uppdaterar..." : "Uppdatera"} style={{ backgroundColor: "green", color: "#fff", padding: 5, border: 0, width: "100%" }} />
				</div>
				<div style={{ marginTop: 10, }}>
					<input onClick={() => removeSpot()} type="button" value={removed ? "Tar bort..." : "Ta bort"} style={{ backgroundColor: "red", color: "#fff", padding: 5, border: 0, width: "100%" }} />
				</div>

				<text> Länk för att endast visa karta och bara badplatser som har samma AnvändarID (clientID): </text>
				<a href={window.location.origin + "/?map=true&clientID=" + clientID} target="_blank" >{window.location.origin + "/?map=true&clientID=" + clientID}</a>

			</>
		)
	}

	const addSpot = () => {
		setAdded(true);
		added = true;
		
		const token = window.sessionStorage.getItem('loginToken')

		axios.post("/api/spots/add",
			qs.stringify({
				name,
				clientID,
				longitude,
				latitude,
				waterquality,
				algiers,
				image,
				link,
				rentKayakLink,
				sensorID,
				parking: document.getElementById("opt1").checked,
				toilet: document.getElementById("opt2").checked,
				handicap: document.getElementById("opt3").checked,
				isPrivate,
			}), {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
				"Authorization": `Bearer ${token}`
			}
		}).then(function (response) {
			GetSpotsFromServer()
			
		}).catch(function (error) {
			//setAdded(false);
		});
	}

	const removeSpot = () => {
		setRemoved(true);
		removed = true;
		const token = window.sessionStorage.getItem('loginToken')

		axios.post("/api/spots/remove",
			qs.stringify({
				_id
			}), {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
				"Authorization": `Bearer ${token}`
			}
		}).then(function (response) {
			GetSpotsFromServer()
		}).catch(function (error) {

		});
	}

	const updateSpot = () => {
		setUpdated(true);
		const token = window.sessionStorage.getItem('loginToken')

		axios.post("/api/spots/update",
			qs.stringify({
				_id,
				name,
				clientID,
				longitude,
				latitude,
				waterquality,
				algiers,
				image,
				link,
				rentKayakLink,
				sensorID,
				parking: document.getElementById("opt1").checked,
				toilet: document.getElementById("opt2").checked,
				handicap: document.getElementById("opt3").checked,
				isPrivate,
			}), {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
				"Authorization": `Bearer ${token}`
			}
		}).then(function (response) {
			GetSpotsFromServer() // need to update the list if user changed the name
			/*if (response.data) {
				setUpdated(true);
			}*/
		}).catch(function (error) {
			//setUpdated(false);
		});
	}


	return (
		<div style={{ marginTop: 50, height: "100vh", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", backgroundImage: `url(${BG})` }}>
			<div style={{ width: 600, padding: 20, backgroundColor: "#fff", borderRadius: 10 }}>
				{renderForm()}
			</div>
		</div>
	);
}


export default DashboardContainer;
