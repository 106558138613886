import React from 'react';
import 'charts.css';
import './TemperatureGraph.css';



// Wants an array with entries: {date: "Fre", temp: 12}
const TemperatureGraph = (props) => {

  const getLineData = () => {
    if (props.tempHistory.length <= 1)
      return []
    let minTemp = 999
    let maxTemp = -999

    props.tempHistory.forEach((day) => {
      minTemp = Math.min(minTemp, day.temp)
      maxTemp = Math.max(maxTemp, day.temp)
    })

    let extremesDiff = Math.max(maxTemp - minTemp, 1)

    let graphEntries = []
    props.tempHistory.forEach((day, index) => {
      let graphEntry = {}
      graphEntry["temp"] = day.temp
      graphEntry["start"] = index === 0 ? 0 : (props.tempHistory[index - 1].temp - minTemp) / extremesDiff
      graphEntry["size"] = (day.temp - minTemp) / extremesDiff
      graphEntries.push(graphEntry)
    })

    props.tempHistory.forEach((day, index) => {
      if (props.tempHistory[index].temp === "") // No temperature
        graphEntries[index].temp = "-"
    })

    return graphEntries
  }


    return (
      <div className="tempGraph">
        <table className="charts-css line" id="my-chart">
          <tbody>
          {getLineData().map((day, index) => {

            const lineStyle = {
              "--start": day.start * 0.5, // needed to lower the whole graph to not clip at the top
              "--size": day.size * 0.5,
              "--color-1": index === 0 ? 'rgba(255, 255, 255, 255)' : 'rgba(255, 255, 255, 0.75)'
            };

            let textHeight = 100 - (((day.size * 70)))
            let textHeightFirst = 100 - (((day.size * 50)))


            // We need to add the first number manually
            if (index === 0){
              return ( <text className="tempGraph_Text_First" style={{"height": `${textHeightFirst}%`}} > {day.temp}° </text> )
              }

            // Last index should only have the graph
            if (index === props.tempHistory.length-1){
              return(
                <tr>
                  <td style={lineStyle}></td>
                </tr>
                )
              }

            return (
              <tr>
                <td style={lineStyle}> <span className="tempGraph_Text" style={{"height": `${textHeight}%`}} > {day.temp}°  </span> </td>
              </tr>
              )
          })}
          </tbody>
        </table>

        <div className="tempGraph_Dates">
          {props.tempHistory.map((day, index) => {
            if(index === props.tempHistory.length-1)
              return ( <text></text> )
            return ( <text className="tempGraph_DateText">{day.date}</text> )
          })}
        </div>
    
      </div>
    );
};

export default TemperatureGraph;
