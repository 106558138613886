import React, { Component } from 'react';
import { withRouter } from 'react-router';
import classNames from 'classnames';
import qs from 'qs';
import './Main.css';
import Map from "../components/Map";
import Paginate from '../components/Paginate';
import Spinner from './Spinner';
import SpotDetails from './SpotDetails';
import SpotListEntry from './SpotListEntry';


class Main extends Component {

	constructor(props) {
		super(props)
		
		// Get clientID from the adress bar. It determines which bath spots we get from the server
		// We only get those who are associated with the ID. But if we send in an empty ID we get all spots
		const { location } = props
		const params = qs.parse(location.search, { ignoreQueryPrefix: true })
		const id = params.clientID ? params.clientID : ""
		const page = params.p ? parseFloat(params.p) : 1
		const spot = params.spot ? params.spot : ""


		this.state = {
			// When we wait for the spots from the server
			isLoading: true,
			clientID: id,
			spots: [],

			// Which spot the mouse is on, makes it's icon enlarge on the map
			hoverID: '',

			// Mobile shows either list or map at a time
			mobile_MapIsVisible: false,

			// list page variables
			curPage: page,
			pageCount: 1,
			itemCount: 1,
			itemsPerPage: 8, // change here if you want other number

			selectedSpotID: spot // the spot._id
		};

		
	}

	componentDidMount() {
		this.fetchData()
	}


	fetchData = async () => {
		this.setState({isLoading: true}) 
		const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/spots/list?id=${this.state.clientID}`);
		const payload = await res.json();

		// Sort spots by name
		payload.spots.sort((a, b) => { return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1 })

		this.setState({spots: payload.spots})
		this.setState({pageCount: Math.ceil(payload.spots.length / this.state.itemsPerPage)})
		this.setState({itemCount: payload.spots.length})
		this.setState({isLoading: false})
	}

	// Get the bath spots for the current page
	getCurrentListSpots = () => {
		let listSpots = []
		const startI = (this.state.curPage-1) * this.state.itemsPerPage
		const endI = Math.min(startI + this.state.itemsPerPage, this.state.itemCount)

		for (let i = startI; i < endI; i++) {
			listSpots.push(this.state.spots[i])
		}
		return listSpots;
	}

	changePage = (index) => {
		if (index === this.state.curPage) return;

		this.setState({curPage: index}) 
		window.scrollTo({
			top: 0, 
			//behavior: 'smooth'
		  });

		// Update the URL search without reloading
		const url = new URL(window.location.href);
		url.searchParams.set('p', index);
		window.history.replaceState(null, null, url);
	}

	// Get array index from id
	getCurrentSpotIndex = () => {
		let returnIndex = -1
		this.state.spots.every((spot, index) => {
			if (spot._id === this.state.selectedSpotID)
			{
				returnIndex = index
				return false // stop the every loop
			}
			else return true
		})
		return returnIndex
	}

	onSpotClicked = (spotId) => {
		this.setSpotSelected(spotId)
	}

	onSpotClosed = () => {
		this.setSpotSelected("")
	}

	setSpotSelected = (spotId) => {
		this.setState({selectedSpotID: spotId})

		// Update the URL search without reloading
		const url = new URL(window.location.href);
		url.searchParams.set('spot', spotId);
		window.history.replaceState(null, null, url);
	}

	
	render() {
		return (			
			<div className= {classNames('main', {'main--is-loadnig': this.state.isLoading})}>

				{ !this.state.isLoading && this.getCurrentSpotIndex() !== -1 &&
				<SpotDetails spotData={this.state.spots[this.getCurrentSpotIndex()]} onClose={this.onSpotClosed} showMapOnly={this.props.showMapOnly} /> }

				{ this.props.showMapOnly && !this.state.isLoading &&
				<Map spots={this.state.spots} onSpotClicked={this.onSpotClicked} showMapOnly={this.props.showMapOnly} />}

				{ !this.props.showMapOnly && 
				<div className="main__wrapper">
					<div className="list-spots">

						{ /* Bath spots left hand list */ }
						<div className="list-spots__list-container">
							<div style={{"padding-top": "35px", "padding-bottom": "35px", "text-align": "center", "font-size": "18px"}}>
								<div>Badtemperatur och annan info för de bästa badplatserna nära Mellerud.</div>
								<div>Tryck på en badplats för mer detaljer.</div>
							</div>
							
							<h2 style={{"padding-bottom": "35px"}}>Utforska badplatser</h2>
							<div className="list-spots__list-container-inner">
								<ul className="list-spots__list" onMouseLeave={() => {this.setState({hoverID : null})} }>
									{!this.state.isLoading && this.getCurrentListSpots().map(spot => {
										return ( <SpotListEntry spotData={spot} onSpotClicked={this.onSpotClicked}></SpotListEntry>	);
									})}
								</ul>
								<button className="list-spots__map-toggler" type="button" onClick={() => this.setState({mobile_MapIsVisible : !this.state.mobile_MapIsVisible})}>{this.state.mobile_MapIsVisible ? 'Visa som lista' : 'Visa på karta'}</button>
							</div>
							{
								<Paginate onPageChanged={this.changePage} {...this.state} />
							}
						</div>
						
						

						{/* Bath spots map */}	
						<div className={classNames({
							'list-spots__map': true,
							'list-spots__map--is-visible': this.state.mobile_MapIsVisible,
						})}>
							{!this.state.isLoading &&
								<Map spots={this.state.spots} onSpotClicked={this.onSpotClicked} />
							}
						</div>
					</div>
					
					{ /* Bottom ad */ }
					{ this.state.clientID === "" &&
					<div>
						<section className="onboard">
							<div className="onboard__content">
								<h1>Är du intresserad av tjänsten?</h1>
								<p className="onboard__text">Vill du också mäta badtemperaturer? Vi har lösningar för uppkopplade bojjar. Kontakta oss för mer information.</p>
								<a className="onboard__btn" href="https://viconia.se/">Kontakta oss</a>
							</div>
						</section>
						<footer className="footer"></footer>
					</div>
					}
				</div>
				}
				
				<Spinner />
			</div>
		);
	}
};

export default withRouter(Main);
