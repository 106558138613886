import React from 'react';
import './SpotInfoIcons.css';
import PSignImg from '../../assets/P-Sign.svg';
import WCSignImg from '../../assets/WC-Sign.svg';
import HandicapSignImg from '../../assets/Handicap-Sign.svg';


const WaterStatus = props => {
    return (
        <div className="infoIcons">
            { props.haveParking && <img className="infoIcons_Icon" src={PSignImg} alt="Parkering"/> }
            { props.haveToilet && <img className="infoIcons_Icon" src={WCSignImg} alt="Toalett"/> }
            { props.haveHandicap && <img className="infoIcons_Icon" src={HandicapSignImg} alt="Handikapp-anpassad"/> }
        </div>
    );
};

export default WaterStatus;
