import React from 'react';
import './WaterStatus.css';
import WaterQuality from './WaterQuality';


const WaterStatus = props => {
    return (
        <div className="waterstatus">
            <div className="waterstatus_columns">
                <text className="waterstatus_temperature">
                    {props.temp}°
                </text>
                {(props.usable === -1 && props.algies === -1) && <text className="waterstatus_NowText">
                    IDAG
                </text> }
                {(props.usable > -1 || props.algies > -1) && <div className='waterstatus_list'>
                    {props.usable > -1 && <WaterQuality isOk = {props.usable === 1} text = "Tjänligt" falseText = "Otjänligt" /> }
                    {props.algies > -1 && <WaterQuality isOk = {props.algies === 0} text = "Algfritt" falseText = "Alger" /> }
                </div> }
            </div>
        </div>
    );
};

export default WaterStatus;
