import React from 'react';
import './Spinner.css';

const Spinner = props => {
    return (
        <span className="spinner">
            <span className="dot1"></span>
            <span className="dot2"></span>
            <span className="dot3"></span>
        </span>
    ); 
};

export default Spinner;