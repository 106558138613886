import React from 'react';
import './OptionEntry.css';


const OptionEntry = props => {
    return (
        <div className="OptionEntry">
            <text className="OptionEntry_text">{props.header}</text>
            {props.children}
        </div>
    );
};

export default OptionEntry;
