import React, { Component } from 'react';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link
  } from "react-router-dom";
import Main from './Main';
import qs from 'qs';
import Header from './Header';
import './App.css';
import LoginContainer from "../containers/login.container";
import DashboardContainer from "../containers/dashboard.container";


class App extends Component {

	constructor(props) {
		super(props)

		const params = qs.parse(window.location.search, { ignoreQueryPrefix: true })
		const onlyMap = params.map ? params.map : "false"

		this.state = {
			showMapOnly: onlyMap === "true",
		}
	}
	

	render() {
		return (
			<>
				<Router>
					<Switch>
						<Route exact path="/dashboard">
							<Header />
							<DashboardContainer />
						</Route>
						<Route exact path="/login">
							<Header />
							<LoginContainer />
						</Route>
						<Route path="/">
							{!this.state.showMapOnly && <Header />}
							<Main showMapOnly = {this.state.showMapOnly} />
						</Route>
					</Switch>
				</Router>

			</>
		);
	}
}

export default App;
