import React from 'react';
import logo from '../assets/viconia.png';
import './Header.css';

const Header = () => {
	return (
		<header className="header">
			<a href="https://viconia.se" className="header__logo" target="_blank">
				<img src={logo} alt="logo" />
			</a>
		</header>
	);
};

export default Header;
