import React, { useEffect, useState } from 'react';
import './SpotDetails.css';
import defaultBeachImg from '../assets/beach.jpg';
import geoImg from '../assets/geo-alt.svg';
import infoImg from '../assets/Info.svg';
import paddlesImg from '../assets/double-paddle-svgrepo-com.svg';
import WaterStatus from './SpotDetails/WaterStatus';
import TemperatureGraph from './SpotDetails/TemperatureGraph';
import SpotInfoIcons from './SpotDetails/SpotInfoIcons';
import DailyForecast from './SpotDetails/DailyForecast';
import moment from 'moment';



const SpotDetails = props => {

    // State
    const [currentTemp, setCurrentTemp] = useState(0);
    const [tempHistory, setTempHistory] = useState([]) 

    useEffect( () => {
        // Calculate graph values and texts
        let graphValues = []
        let temps = props.spotData.lastWeekTemp
        for (let i = 0; i < 7; i++)
        {
            let text = i == 1 ? "IGÅR" : moment().subtract(i, "days").format("D/M")
            let temperature = (temps.length <= i || temps[i] === "") ? "" : Math.round(temps[i])
            graphValues.push({date: text, temp: temperature})
        }
        
        setCurrentTemp((temps.length > 0 && temps[0]) ? graphValues[0].temp : "-")
        setTempHistory(graphValues.reverse())
    }, []);


    return (
        <div className="spotd_overlay" >
            <div className="spotd_overlay_click" onClick={props.onClose}> </div>
            <div className="spotd_base">
                
                <div className="spotd_topbar">
                    <h2>{props.spotData.name}</h2>
                    <div className="spotd_box_weather_today">
                        { props.spotData.forecast.length > 0 && <DailyForecast forecast={props.spotData.forecast[0]} hideDay={1} /> }
                    </div>
                </div>
                <div className="spotd_imgbox_overlay">
                    <div className="spotd_imgbox_overlay_top">
                        <SpotInfoIcons haveParking={props.spotData.parking} haveToilet={props.spotData.toilet} haveHandicap={props.spotData.handicap}/>
                        <div className="spotd_box_weather">
                            { props.spotData.forecast.length > 1 && <DailyForecast forecast={props.spotData.forecast[1]}/> }
                            { props.spotData.forecast.length > 2 && <DailyForecast forecast={props.spotData.forecast[2]}/> }
                            { props.spotData.forecast.length > 3 && <DailyForecast forecast={props.spotData.forecast[3]}/> }
                        </div>
                    </div>

                    
                    <div className="spotd_imgbox_overlay_bottom">
                        <div className= "spotd_imgbox_overlay_bottom_buttonrow">
                            <a className="spotd_button" href={props.spotData.link}>
                                <img src={infoImg} className="spotd_geoImg" />
                                <text>Info</text>
                            </a>
                            
                            { props.spotData.rentKayakLink && 
                            <a className="spotd_button" href={props.spotData.rentKayakLink} >
                            <img src={paddlesImg} className="spotd_paddlesImg" />
                                <text>Hyr kayak</text>
                            </a>
                            }
                        
                            <a class="spotd_button" href={`http://www.google.com/maps/place/${props.spotData.latitude},${props.spotData.longitude}`}>
                                <img src={geoImg} className="spotd_geoImg" />
                                <text>Hitta hit</text>
                            </a>
                        </div>
                        
                        <div className="spotd_bottombar_waves"/>
                    </div>

                </div>
                
                
                <img className="spotd_imgbox" src={props.spotData.image ? props.spotData.image : defaultBeachImg} alt="defaultBeachImg" />
                <div className="spotd_bottombar"> 
                    <TemperatureGraph tempHistory={tempHistory}/>
                    <WaterStatus temp={currentTemp} usable={props.spotData.waterquality} algies={props.spotData.algiers} />  
                </div>
            </div>

        </div>
    )
};

export default SpotDetails;
