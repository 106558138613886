import React, {useState, useEffect} from 'react';
import { withRouter } from 'react-router';
import ReactMapGL from 'react-map-gl';
import { Marker} from 'react-map-gl';
import Icon from '../assets/icon.png';
import geoImg from '../assets/geo-pos.svg';
import './Map.css';
import './Modal.css';
import WebMercatorViewport, { Bounds } from "viewport-mercator-project"

const Map = (props) =>  {

  useEffect(() => {
    // Get user location
    if (navigator.geolocation)
        navigator.geolocation.getCurrentPosition(getCurrentUserPosition)

    if (props.spots.length > 0 && window.innerHeight >= 320 && window.innerWidth >= 320)
    {
      setViewport((viewport) => {
        const NEXT_VIEWPORT = new WebMercatorViewport({
          ...(viewport)
        }).fitBounds(getBounds(), {
           padding: 100
        });

        return NEXT_VIEWPORT;
      });
    }
  }, []);


  const getCurrentUserPosition = (position) => {
    if (!position || !position.coords)
      return
      
    setUserLocation({ latitude: position.coords.latitude, longitude: position.coords.longitude })
    let newViewport = viewPort
    newViewport.latitude = position.coords.latitude
    newViewport.longitude = position.coords.longitude
    setViewport(newViewport)
  }


  const getCenterPos = () => {
    let bounds = getBounds()
    let minLng = bounds[0][0]
    let minLat = bounds[0][1]
    let maxLng = bounds[1][0]
    let maxLat = bounds[1][1]

    return {"latitude" : (minLat + maxLat)/2, "longitude" : (minLng + maxLng)/2 }
  }

  // The start position of the map
  const getBounds = () => {
    let minLng = 180
    let maxLng = -180
    let minLat = 90
    let maxLat = -90

    props.spots.forEach(spot => {
      minLng = Math.min(minLng, spot.longitude)
      maxLng = Math.max(maxLng, spot.longitude)
      minLat = Math.min(minLat, spot.latitude)
      maxLat = Math.max(maxLat, spot.latitude)
    })

    const southWest = [minLng, minLat];
    const northEast = [maxLng, maxLat];
    return [southWest, northEast];
  }



  // State
  const [modalIsOpen,setIsOpen] = useState(null);
  const [userLocation, setUserLocation] = useState(null)
  const [viewPort, setViewport] = useState({
      latitude: 58.7030887,
      longitude: 12.4682782,
      width: "100%",
      height: props.showMapOnly ? "100vh" : "calc(100vh - 135px)",
      zoom: 9,
  });

  
 
  return (
    <div onClick={() => setIsOpen(null)}>
      <ReactMapGL

        {...viewPort}
        mapboxApiAccessToken='pk.eyJ1IjoiY29vbDI0NyIsImEiOiJjazg1anZvYWUwMmx3M2htbXFxNWNjNmlwIn0.yiHpp-fXNb4W3CCm9l8VhQ'
        mapStyle="mapbox://styles/cool247/ckb3gpqux0k0j1io63l4gcwar"

        onViewportChange={viewport => {
          setViewport(viewport)
        }}
      >
        {props.spots && props.spots.map((spot, index) => {


          return <Marker key={`marker-${index}`} latitude={spot.latitude} longitude={spot.longitude} offsetLeft={-16} offsetTop={-16}>
          <div>
            <img className="marker" src={Icon} onClick={() => props.onSpotClicked(spot._id) } style={{color: '#fff', width: 32, cursor: "pointer"}}  />
            <div className="marker_text">
              <text className="map_text">{spot.name}</text>
              <text className="map_text_temp">{(spot.lastWeekTemp.length > 0 && spot.lastWeekTemp[0] ) ? Math.round(spot.lastWeekTemp[0])+"°" : ""}</text>
            </div>
          </div>

        </Marker>
        })}

        {userLocation && <Marker key={`marker-user`} latitude={userLocation.latitude} longitude={userLocation.longitude} offsetLeft={-18} offsetTop={-36}>
          <div >
            <img className="marker_user" src={geoImg} style={{width: 36}}  />
          </div>

        </Marker> }

      </ReactMapGL>
    </div>
  );
}

export default withRouter(Map);


