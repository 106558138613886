import React, { Component } from 'react';
import './SpotListEntry.css';
import defaultBeachImg from '../assets/beach.jpg';
import waveImg from '../assets/SingleWave.svg';
import SpotInfoIcons from './SpotDetails/SpotInfoIcons';
import DailyForecast from './SpotDetails/DailyForecast';
import kayakLogo from '../assets/kayakomat-logo.png';


const SpotListEntry = props => {

    const getWaterTemp = () => {
        return ( props.spotData.lastWeekTemp.length > 0 && props.spotData.lastWeekTemp[0]) ? Math.round(props.spotData.lastWeekTemp[0]) : "-"
    }

    return (
        <div className="spotlistentry" onClick={() => props.onSpotClicked(props.spotData._id)}>
            <img className="spotlistentry_img" src={props.spotData.image ? props.spotData.image : defaultBeachImg} alt="" />
            <div className="spotlistentry_inner">
                <div className="spotlistentry_leftbox">
                    <text className="spotlistentry_header">{props.spotData.name}</text>
                    <div className="spotlistentry_leftbox_icons">
                        <SpotInfoIcons haveParking={props.spotData.parking} haveToilet={props.spotData.toilet} haveHandicap={props.spotData.handicap}/>
                    </div>
                </div>
                <div className="spotlistentry_rightbox">
                    <div className="spotlistentry_weather">
                        { props.spotData.forecast.length > 0 && <DailyForecast forecast={props.spotData.forecast[0]} hideDay={1} /> }
                    </div>
                    
                    <div className="spotlistentry_water">
                        <img className="spotlistentry_watertemp_img" src={waveImg} alt=""></img>
                        <text className="spotlistentry_watertemp_text">{getWaterTemp()}°</text>
                    
                    </div>
                </div>
                { props.spotData.rentKayakLink && <div className="spotlistentry_rightdownbox">
                    <a href='https://www.kayakomat.com/location/65a7e0a75339908be5fd418e' target="_blank" style={{paddingTop:"5px"}} >
                        <img src={kayakLogo} alt="kayakLogo" style={{height:"30px"}}/>
                    </a>
                </div> }
            </div>
        </div>
    )
}

export default SpotListEntry;
