import React from 'react';
import './DailyForecast.css';
import ArrowImg from '../../assets/NarrowArrow.svg';
import weatherImg1 from '../../assets/weather/1.svg';
import weatherImg3 from '../../assets/weather/3.svg';
import weatherImg5 from '../../assets/weather/5.svg';
import weatherImg6 from '../../assets/weather/6.svg';
import weatherImg7 from '../../assets/weather/7.svg';
import weatherImg8 from '../../assets/weather/8.svg';
import weatherImg10 from '../../assets/weather/10.svg';
import weatherImg11 from '../../assets/weather/11.svg';
import weatherImg15 from '../../assets/weather/15.svg';
import weatherImg17 from '../../assets/weather/17.svg';
import weatherImg21 from '../../assets/weather/21.svg';


const DailyForecast = props => {

    const getWeatherIcon = () => {
        let nr = props.forecast.icon
        switch(nr) {
            case 1: return weatherImg1;
            case 2: return weatherImg1;
            case 3: return weatherImg3;
            case 4: return weatherImg3;
            case 5: return weatherImg5;
            case 6: return weatherImg6;
            case 7: return weatherImg7;
            case 8: return weatherImg8;
            case 9: return weatherImg8;
            case 10: return weatherImg10;
            case 11: return weatherImg11;
            case 12: return weatherImg8;
            case 13: return weatherImg8;
            case 14: return weatherImg10;
            case 15: return weatherImg15;
            case 16: return weatherImg17;
            case 17: return weatherImg17;
            case 18: return weatherImg8;
            case 19: return weatherImg10;
            case 20: return weatherImg10;
            case 21: return weatherImg21;
            case 22: return weatherImg8;
            case 23: return weatherImg10;
            case 24: return weatherImg10;
            case 25: return weatherImg15;
            case 26: return weatherImg17;
            case 27: return weatherImg17;
          }
    }

    return (
        <div className="df_outer">
            {!props.hideDay && <text className="df_daytext">{props.forecast.weekday}</text> }
            <div className="df_outer_weather">
                <text className="df_temperaturetext"> {Math.round(props.forecast.temperature)}°</text>
                <img className="df_weatherIcon" src={getWeatherIcon()} /> 
            </div>
            <div className="df_outer_wind">
                <text className="df_windtext">{Math.round(props.forecast.windSpeed)}<span className="df_windtext_ms"> m/s</span></text>
                <div className="df_windarrow_offset">
                    <img className="df_windarrow" src={ArrowImg} style={{"transform": `rotate(${props.forecast.windDirection}deg)`}} /> 
                    </div>
            </div>
        </div>
    );
};

export default DailyForecast;
