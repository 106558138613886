import React, { Component, useState, useEffect } from 'react';
import axios from '../api/axios';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import qs from "qs";
import BG from "../assets/bg.jpg";

const LoginContainer = () => {

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(false);

    useEffect(() => {
        window.sessionStorage.setItem('loginToken', "")
        const error = qs.parse((window.location.search))["?error"];
        if (error)
            setError(true);
    }); //[]

    const tryLogin = () => {
        axios.post("/api/auth/sign-in",
            qs.stringify({
                username: username,
                password: password,
            }), {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        }).then(function (response) {
            window.sessionStorage.setItem('loginToken', response.data.token)
            window.location = "/dashboard"
        }).catch(function (error) {
            setError(true);
        });
    }

    return (
        <div style={{ marginTop: 50, height: "100vh", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", backgroundImage: `url(${BG})` }}>
            <div style={{ width: 300, padding: 20, backgroundColor: "#fff", borderRadius: 10 }}>
                <div>
                    <span slot>Användarnamn</span>
                    <br />
                    <input onInput={(e) => setUsername(e.target.value)} style={{ width: "100%", marginTop: 5 }} type="text"></input>
                </div>
                <div>
                    <span style={{ marginTop: 10 }}>Lösenord</span>
                    <br />
                    <input onInput={(e) => setPassword(e.target.value)} style={{ width: "100%", marginTop: 5 }} type="password"></input>
                </div>
                {error &&
                    <span style={{ fontSize: 11, color: "red" }}>Fel lösenord eller användarnamn!</span>
                }

                <input onClick={tryLogin} type="button" style={{ width: "100%", padding: 10, marginTop: 10, background: "steelblue", border: 0, color: "#fff" }} value="Logga in" />
            </div>
        </div>
    );
}


export default LoginContainer;
